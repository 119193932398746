.text-grid-dividers {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	.item {
		border-bottom: solid 1px #D9D9D9;
		padding-bottom: 20px;
		.heading {
			font-size: rem-calc(22);
			line-height: 1.5;
			margin-bottom: 0;
		}
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(550px) {
	.text-grid-dividers {
		grid-template-columns: 1fr 1fr;
		.item {
			border-right: solid 1px #D9D9D9;
			border-bottom: 0;
			padding-bottom: 0;
			padding-right: 20px;
		}
		.item:nth-child(even),
		.item:last-child {
			border-right: 0;
		}
	}
}

@include breakpoint(950px) {
	.text-grid-dividers {
		grid-template-columns: 1fr 1fr 1fr;
		.item:nth-child(even) {
			border-right: solid 1px #D9D9D9;
			padding-right: 20px;
		}
	}
}