.highlight-features {
	background-color: $brand;
	color: $white;
	padding: $full-section-spacing-small 0;
	p {
		color: $white;
	}
	.highlights {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 40px;
	}
	.highlight-feature {
		text-align: center;
		img {
			margin-bottom: 20px;
		}
		p {
			font-weight: $main-bold;
			margin-bottom: 0;
		}
	}
}

@include breakpoint(580px) {
	.highlight-features .highlights {
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: flex-start;
		.highlight-feature {
			flex-basis: 33.33%;
		}
	}
}

@include breakpoint(large) {
	.highlight-features {
		padding: $full-section-spacing 0;
		p {
			color: $white;
		}
	}
}