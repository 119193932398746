.modal {
	overflow-y: auto;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 200;
}
.modal-overlay {
	background: rgba($brand, .9);
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 200;
}

.modal-content-wrapper {
	min-height: 100vh;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 210;
}

.modal-content {
	max-width: 1000px;
	background: $white;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	overflow-y: auto;
	position: relative;
}

.close-modal {
	background: $brand;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
	position: absolute;
	top: 20px;
	right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:hover, &:focus-visible {
		background-color: $brand-accent;
	}
	img {
		margin: 0;
	}
}