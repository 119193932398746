.form-section {
	background-color: $off-white;
	color: $brand;
	padding: 0 0 75px;
	.text {
		text-align: center;
		margin-bottom: 50px;
	}
	.button {
		margin-bottom: 0;
	}
}

@include breakpoint(large) {
	.form-section {
		padding: 0 0 150px;
	}
}