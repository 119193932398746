$media-object-breakpoint: 850px;

.media-object {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
}

@include breakpoint($media-object-breakpoint) {
	.media-object {
		max-width: 100%;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		> div {
			flex-basis: 50%;
		}
		.text {
			padding: 10px 30px;
		}
		.media {
			img {
				margin: 0;
			}
		}
	}
}

// Wrapper for alternating media objects
@include breakpoint($media-object-breakpoint) {
	.media-objects-alternate {
		.media-object:nth-child(odd) .media {
			order: 1;
		}
	}
}