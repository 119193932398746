.offscreen-nav {
	width: 100%;
	background: $brand;
	font-family: $main-font;
	font-weight: $main-normal;
	color: $main-color;
	padding: 0 0 50px;
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	transform: translateX(100%);
	transition: transform .3s;
	&.nav-open {
		transform: translateX(0);
		z-index: 19;
		.small-nav {
			display: block;
		}
	}
}

.small-nav {
	max-width: 400px;
	text-align: center;
	padding-top: 150px;
	margin: 0 auto;
	display: none;
	a, button {
		font-size: rem-calc(24);
		font-weight: $main-bold;
		color: $white;
		line-height: 1.25;
		text-decoration: none;
		&.current, &:hover, &:focus-visible {
			color: $light-green;
		}
	}
	li {
		margin-bottom: 30px;
	}
}



.large-nav {
	font-family: $main-font;
	font-size: 1rem;
	position: relative;
	a {
		font-weight: $main-bold;
		color: $white;
		text-transform: uppercase;
		line-height: 1;
		text-decoration: none;
		display: inline-block;
		&.current,
		&:hover,
		&:focus-visible  {
			color: $light-green;
		}
	}
	ul {
		line-height: 1;
		display: flex;
		align-items: center;
		gap: 30px;
		@include breakpoint(1200px) {
			gap: 52px;
		}
	}
	li {
		line-height: 1;
	}
}