.hero {
	height: 500px;
	background: url("https://fromley.imgix.net/whimbrel/hero/bg_rooftop_deck_rendering.jpg?auto=format&w=480") no-repeat center top #383b3e;
	background-size: cover;
	.hero-content {
		max-width: 430px;
		padding-top: 200px;
		margin: 0 auto;
	}
	h1 {
		font-size: rem-calc(36);
		text-align: center;
		line-height: 1.11;
		text-shadow: 0 4px 23px #000;
		margin: 0;
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.hero {
		background-image: url("https://fromley.imgix.net/whimbrel/hero/bg_rooftop_deck_rendering.jpg?auto=format&dpr=2&q=40&w=480");
	}
}

@include breakpoint(560px) {
	.hero {
		background-image: url("https://fromley.imgix.net/whimbrel/hero/bg_rooftop_deck_rendering.jpg?auto=format&w=960");
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url("https://fromley.imgix.net/whimbrel/hero/bg_rooftop_deck_rendering.jpg?auto=format&dpr=2&q=40&w=960");
		}
	}
}

@include breakpoint(960px) {
	.hero {
		background-image: url("https://fromley.imgix.net/whimbrel/hero/bg_rooftop_deck_rendering.jpg?auto=format&w=1600");
		height: 920px;
		background-position: center bottom;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url("https://fromley.imgix.net/whimbrel/hero/bg_rooftop_deck_rendering.jpg?auto=format&dpr=2&q=40&w=1600");
		}
		.hero-content {
			max-width: 900px;
			padding-top: 310px;
		}
		h1 {
		   font-size: rem-calc(70);
		   text-align: center;
		   line-height: 1;
		}
	}
}

.hero-bg-slider {
	background-image: none;
	overflow: hidden;
	position: relative;
	.hero-content {
		margin-top: 0;
		padding-top: 0;
	}
	.inner {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 50;
	}
	.bg-slider {
		max-height: 100%;
		z-index: 30;
		.glide__slide {
			height: 500px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
				margin: 0;
			}
		}
		.glide__track {
			height: 500px;
		}
	}
}

@include breakpoint(960px) {
	.hero-bg-slider {
		.bg-slider {
			max-height: 100%;
			.glide__slide {
				height: 920px;
				img {
					object-position: bottom;
				}
			}
			.glide__track {
				height: 920px;
			}
		}
	}
}