/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

$imgcdn: 'https://fromley.imgix.net/woven';
$base-spacing: 30px;


//Font families
$sans-serif-font: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
$serif-font:Georgia, Times, Times New Roman, serif;
$heading-font: $sans-serif-font;
$main-font: $sans-serif-font;
$alt-font: $sans-serif-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$heading-normal: $main-normal;
$heading-bold: $main-bold;

//Font sizes
$font-sizes: (
  small: (rem-calc(14), 1.5),
  default: (rem-calc(16), 1.5),
  large: (rem-calc(20), 1.5),
  small-desktop: (rem-calc(16), 1.5),
  default-desktop: (rem-calc(18), 1.5),
  large-desktop: (rem-calc(22), 1.5)
);


//Styleguide colors
$brand:		   #332B2B;
$brand-accent: #326D6C;
$dark-green:   #365153;
$light-green:  #71A6A5;
$gray:         #353536;
$light-gray:   #C9CACB;
$off-white:    #F2EEE6;
$white:        #FFFFFF;
$black:        #353536;

$colors: (
	'brand':			 		 $brand,
	'brand-accent':		 $brand-accent,
	'dark-green':      $dark-green,
	'light-green':     $light-green,
	'gray':            $gray,
	'light-gray':      $light-gray,
	'off-white':       $off-white,
	'white':           $white,
	'black':           $black
);

//Gradients, for use on backgrounds only
$gradients: (
	'black-top-down': linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.8);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $black;

//Link colors
$link-base: $brand;
$link-active: $brand;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow-brand: 0 1px 1px rgba(0, 109, 121, 0.5);
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow-subtle: 0 0 4px rgba(0, 0, 0, 0.2);
$box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
$box-shadow-heavy: 0 0 70px rgba(0, 0, 0, 0.2);

//Rounded corners
$border-round: 1000px;
$border-radius: 17px;

//Section spacing
$content-section-spacing-small: 	100px;
$content-section-short:						50px;
$content-sub-section-spacing-small: 70px;
$full-section-spacing-small: 		50px;
$intro-section-spacing-small: 		48px;
$content-section-spacing: 			150px;
$content-sub-section-spacing: 		20px;
$full-section-spacing: 				100px;
$intro-section-spacing: 			96px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test,
);

$hero-bg-color: (
	'home-orange': #ff9900,
);