/*----------------------------------------------------------*\
    Lists

    In addition to standard list styles, a list with icons
    applied as ::before pseudo-elements is implemented.
    To add additional icons, follow the examples below.
    The split-list-* classes will make your list into
    multiple columns on larger views while remaining a
    single column on smaller views.

\*----------------------------------------------------------*/

ul {
	padding: 0;
	margin: 0 0 $base-spacing 38px;
	li {
		padding: 0 0 0 17px;
		margin: 0 0 10px;
		list-style: none;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
		&::before {
			content: '';
			background-color: $brand;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			display: block;
			position: absolute;
			left: 0;
			top: 11px;
			@include breakpoint(large) {
				top: 16px;
			}
		}
	}
	li ul {
		margin: .666em 0 .75em;
		li {
			padding-left: 24px;
		}
	}
}
ol {
	margin: $base-spacing 0 $base-spacing 20px;
	li {
		line-height: 1.4;
		margin: 0 0 14px;
	}
	ol {
		list-style: lower-alpha;
	}
	ol ol {
		list-style: lower-roman;
	}
}
ul.bare {
	li {
		padding-left: 0;
		position: relative;
		&::before {
			display: none;
		}
	}
}

ul.text-large li::before {
	top: 10px;
	@include breakpoint(large) {
		top: 12px;
	}
}


@include breakpoint(medium) {
	ul.split-list-2,
	ol.split-list-2 {
		overflow: hidden;
		margin-left: 0;
		padding-left: 0;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		padding-left: 0;
		margin-left: 0;
		overflow: hidden;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
}

/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		padding: 0;
		margin: 0;
		&::before {
			display: none;
		}
	}
	li ul {
		margin: 0;
	}
	li ul li {
		background: none;
		margin: 0;
	}
	li ul li ul {
		display: none;
	}
	li ul li ul li {
		background: none;
	}
}
/* @end */