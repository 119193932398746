.media-overflow-section {
	background: $brand;
	color: $white;
	h3 {
		color: $white;
		margin-bottom: 20px;
	}
	.media {
		margin-left: -.9375rem;
		margin-right: -.9375rem;
		@include breakpoint(medium) {
			margin-left: -1.875rem;
			margin-right: -1.875rem;
		}
	}
	.text {
		padding: 50px 0;
	}
	.lined-highlight {
		padding-top: 22px;
		margin-bottom: 22px;
		border-top: solid 1px #5D6870;
		&:last-child {
			border-bottom: solid 1px #5D6870;
			padding-bottom: 22px;
			margin-bottom: 0;
		}
	}
}

@include breakpoint(800px) {
	.media-overflow-section {
		overflow: hidden;
		.inner {
			min-height: 916px;
			display: flex;
		}
		.text {
			flex-basis: 75%;
			flex-shrink: 1;
			padding-right: 50px;
			align-self: center;
		}
		.media {
			margin-left: 0;
			margin-right: 0;
			flex-basis: auto;
			order: 1;
			position: relative;
			img {
				min-width: 968px;
				margin: 0;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}