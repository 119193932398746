.gallery-grid {
	margin-bottom: $base-spacing;
	display: grid;
	grid-gap: 20px;
	> div {
		display: grid;
		grid-gap: 20px;
	}
	img {
		margin: 0;
	}
	.full {
		grid-template-columns: 1fr;
	}
	.half {
		grid-template-columns: 1fr 1fr;
	}
	.two-one {
		grid-template-columns: 2fr 1fr;
	}
	.one-two {
		grid-template-columns: 1fr 2fr;
	}
}