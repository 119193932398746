.single-text-callout {
	@include font-size(large);
	text-align: center;
	padding-bottom: $content-section-short;
	> *:last-child {
		margin-bottom: 0;
	}
	.highlight-text {
		color: $brand;
	}
	.light-text {
		color: #D9D9D9;
	}
}

@include breakpoint(large) {
	.single-text-callout {
		@include font-size(large-desktop);
	}
}