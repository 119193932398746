/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $off-white;
    padding: 50px 0 (100px - $base-spacing);
}

@include breakpoint(large) {
	.page-content {
		padding: 100px 0 (150px - $base-spacing);
	}
}